<template>
  <section>
    <v-row>
      <v-col md="12" v-if="loading">
        <Loading />
      </v-col>

      <v-col md="12" v-if="!loading">
        <section>
          <v-row>
            <v-col md="12">
              <h3 class="mb-5">Email Template</h3>
              <router-link to="/dashboard/emaileditor"
                ><v-btn> Add New Template </v-btn></router-link
              >
            </v-col>
            <v-col md="3">
              <v-text-field
                outlined
                placeholder="Email Address"
                label="Email Address"
                v-model="email"
              ></v-text-field>
            </v-col>
            <v-col md="3">
              <v-text-field
                outlined
                placeholder="Agent Name"
                label="Agent Name"
                v-model="agentName"
              ></v-text-field>
            </v-col>
            <v-col md="3">
              <v-text-field
                outlined
                placeholder="PropertyId"
                label="PropertyId"
                v-model="propertyId"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12">
              <div></div>
              <h3 class="table-heading flexBox-space">
                <span> Email Template List </span>
              </h3>
              <v-simple-table class="tableborder">
                <thead>
                  <tr>
                    <th width="10">S/N</th>
                    <th>Template Name</th>
                    <th>User For</th>
                    <th>Status</th>
                    <th>Email Test</th>
                    <th width="10">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="loading">
                    <td colspan="3">
                      <v-progress-circular indeterminate></v-progress-circular>
                    </td>
                  </tr>
                  <tr v-for="(data, index) in templates" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>{{ data.title }}</td>
                    <td>{{ data.usedFor }}</td>
                    <td>
                      <v-switch
                        v-model="data.status"
                        @change="
                          changeStatus({ _id: data._id, status: data.status })
                        "
                      ></v-switch>
                    </td>
                    <td>
                      <v-btn
                        @click="testEmailAct(data._id)"
                        v-if="data._id != id"
                        >Test Template</v-btn
                      ><v-btn
                        v-if="data._id == id && emailLoading"
                        loading
                      ></v-btn>
                    </td>
                    <td>
                      <router-link :to="`/dashboard/emaileditor/${data._id}`">
                        <v-icon> mdi-pencil </v-icon>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
        </section>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loading from "@/components/dashboard/Loading";
export default {
  name: "EmailTemplateList",
  computed: {
    ...mapGetters(["allEmailTemplates"]),
  },
  components: {
    Loading,
  },
  data() {
    return {
      loading: true,
      page: 1,
      limit: 20,
      templates: [],
      agentName: "Binayak Dhakal",
      propertyId: "1234234",
      email: "binayakdhakal999@gmail.com",
      emailLoading: false,
      id: undefined,
    };
  },

  methods: {
    ...mapActions(["listEmailTempaltes", "createEmailTemplate","testEmail"]),
    async changeStatus(data) {
      await this.createEmailTemplate(data);
    },
    async testEmailAct(id) {
      this.id = id;
      this.emailLoading = true;
      let data = {};
      data.agentName = this.agentName;
      data.propertyId = this.propertyId;
      data.id = id;
      data.email = this.email;
      await this.testEmail(data);
      this.id = "";
      alert("Email Sent Successfully");
      this.emailLoading = false;
    },
  },

  async created() {
    await this.listEmailTempaltes({ limit: this.limit, page: this.page });
    this.templates = this.allEmailTemplates.results;
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.dateHighlight {
  background: yellow;
}
.no-pad-tb {
  padding-top: 0;
  padding-bottom: 0;
}
h4 {
  margin-bottom: 30px;
}
.v-icon {
  font-size: 16px;
  cursor: pointer;
}
.downloadbtn {
  margin-left: 20px;
}
.sub-menus {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 20px;
  padding-left: 0;
  li {
    a {
      display: block;
      padding: 10px 20px;
      text-decoration: none;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
      margin-right: 10px;
      color: $dark;
      background: $white;
      border-radius: 5px;
      // color: $white;
    }
  }
}
.actionbtn {
  margin-bottom: 20px;
}
.activeTab {
  background: rgb(195, 195, 196);
  color: rgb(20, 20, 20);
  a {
    color: rgb(5, 5, 5);
  }
}
.mr-btm {
  margin-bottom: 30px;
  margin-top: -20px;
}
a {
  text-decoration: none;
}
.mr-left {
  margin-left: 10px;
}
.dropdownlist {
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  margin: 0;
  padding: 8px 0;
  margin-top: -30px;
  li {
    list-style: none;
    padding: 8px 20px;
  }
}
.dropBox {
  list-style: none;
  padding-left: 0;
  position: absolute;
  background: #fff;
  box-shadow: 0 5px 10px #efefef;
  li {
    padding: 10px 25px;
    border-bottom: 1px solid #dfdddd;
    cursor: pointer;
    &:last-of-type {
      border: none;
    }
  }
}
.relRow {
  position: relative;
  .dropBox {
    position: absolute;
    width: 150px;
    display: none;
  }
  &:hover {
    .dropBox {
      display: block;
    }
  }
}
.fixPop {
  background: rgba(0, 0, 0, 0.2);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  justify-content: center;
  z-index: 99999;

  .v-form {
    width: 500px;
  }
  .formBox {
    background: #fff;
    border-radius: 10px;
    width: 550px;
    padding: 30px;
    margin: 0 auto;
    margin-top: 50px;
    position: relative;
    .mdi-close {
      position: absolute;
      right: 20px;
      top: 15px;
    }
  }
}
.rightmovePopup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  iframe {
    width: 500px;
    height: 500px;
  }
}
.flexBox-space {
  display: flex;
  justify-content: space-between;
}
</style>
